import { render, staticRenderFns } from "./staff-attendance.vue?vue&type=template&id=df86a540&"
import script from "./staff-attendance.vue?vue&type=script&lang=js&"
export * from "./staff-attendance.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('df86a540')) {
      api.createRecord('df86a540', component.options)
    } else {
      api.reload('df86a540', component.options)
    }
    module.hot.accept("./staff-attendance.vue?vue&type=template&id=df86a540&", function () {
      api.rerender('df86a540', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/reports/staff-attendance.vue"
export default component.exports